import React, { Component, Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DelayedLoadingMessage from './components/DelayedLoadingMessage/';
import LoggedOutToast from './components/LoggedOutToast/';
import HelpScout from './components/HelpScout/';
import PhreesiaSupport from './components/PhreesiaSupport/';
import Rollbar from "rollbar";
import { LinearProgress } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#1e8555', secondary: '#f5f5f5' }, // Purple and green play nicely together.
    },
    typography: {
        useNextVariants: true,
        "fontFamily": "\"Raleway\", \"Helvetica\", \"Arial\", sans-serif",
    }
});

const DashboardPage = React.lazy(() => import('./pages/Dashboard'));
const OnBoardingPage = React.lazy(() => import('./pages/OnBoarding'));
const PatientSearch = React.lazy(() => import('./pages/PatientSearch'));
const AccountSettings = React.lazy(() => import('./pages/AccountSettings'));
const LoginSettings = React.lazy(() => import('./pages/AccountSettings/login_settings'));
const ReferralLanding = React.lazy(() => import('./pages/ReferralLanding'));
const AcceptViaLinkConfirmation = React.lazy(() => import('./pages/AcceptViaLinkConfirmation'));
const LoginPage = React.lazy(() => import('./pages/Login'));
const MaintenancePage = React.lazy(() => import('./pages/Maintenance'));
const MassAppointmentTypeChange = React.lazy(() => import('./pages/MassAppointmentTypeChange'));
const SwitchReporting = React.lazy(() => import('./pages/SwitchReporting'));
const RebookReporting = React.lazy(() => import('./pages/RebookReporting'));
const RebookHistory = React.lazy(() => import('./pages/RebookHistory'));
const RebookForm = React.lazy(() => import('./pages/Rebook'));
const NewCaseInPhreesiaPage = React.lazy(() => import('./pages/NewCaseInPhreesia'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice_name: null,
      impersonating:  null,
      user_id:  null,
      logged_out:false,
      onboarded:null,
      user_role:'can_view',
      authenticity_token: null,
      active_dashboard_cards:[],
      rollbar: new Rollbar({
          accessToken: 'f6b304957136435e85a776ae0543eb0e',
          captureUncaught: true,
          captureUnhandledRejections: true,
          payload: { environment: "production" }
        }),
      loaded: false
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch('/api/account_overview', { cache: "no-store" });
      const responseJson = await response.json();
      const data = responseJson.data;

      if (!responseJson.data.message) {
        this.setState({
          loaded: true,
          practice_name: data.practice_name,
          user_id: data.user_id,
          impersonating: data.impersonating,
          onboarded: data.onboarded,
          user_role: data.role,
          authenticity_token: data.authenticity_token,
          created_at: data.created_at,
          logged_out: false
        });
        sessionStorage.setItem('practice_name', data.practice_name);

        //reset session var if impersonating
        if (data.impersonating) {
          console.log("Cleared Recent");
          sessionStorage.removeItem('recent');
        }
      } else {
        //logged out
        this.setState({
          loaded: true,
          logged_out: true,
          authenticity_token: data.authenticity_token,
          authenticity_token2: data.authenticity_token2
        });
      }
    } catch {
      //logged out
      this.setState({ loaded: true, logged_out: true });
    }
  }

  render() {
    const { 
      loaded,
      impersonating, 
      practice_name, 
      onboarded, 
      user_role, 
      logged_out, 
      authenticity_token, 
      authenticity_token2, 
      created_at, 
      user_id
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        { !loaded
          ? <LinearProgress color="primary" style={{width:'80%', margin:'20% auto 20px'}} />
          : [<Router basename={process.env.PUBLIC_URL}>
              <React.Suspense fallback={<DelayedLoadingMessage />}>
                <Route exact path="/" render={(props) => <DashboardPage {...props} impersonating={impersonating} user_id={user_id} created_at={created_at} />} />
                <Route path="/dashboard" render={(props) => <DashboardPage {...props} impersonating={impersonating} user_id={user_id} created_at={created_at} />} />

                <Route path='/onboarding' component={(onboarded) ? OnBoardingPage : OnBoardingPage} />
                <Route path="/search/:term?" render={(props) => <PatientSearch {...props} impersonating={impersonating} practice_name={practice_name} user_role={user_role} />} />
                <Route path="/settings/:tab?" render={(props) => <AccountSettings {...props} impersonating={impersonating} practice_name={practice_name} user_role={user_role} />} />
                <Route path="/login_settings" render={(props) => <LoginSettings {...props} impersonating={impersonating} practice_name={practice_name} user_role={user_role} />} />
                <Route path="/share/:id?" component={ReferralLanding} />
                <Route exactly path="/sign_in" component={() => window.location = window.location.protocol+'//'+window.location.host+'/users/sign_in'} />
                <Route path="/login" render={(props) => <LoginPage {...props} authenticity_token={authenticity_token} authenticity_token2={authenticity_token2}  />} />
                <Route path="/maintenance" render={(props) => <MaintenancePage {...props} />} />

                <Route path="/offer/:id?" component={AcceptViaLinkConfirmation} />
                <Route path="/switch_reporting" component={SwitchReporting} />
                <Route path="/rebook" component={RebookForm} />
                <Route path="/rebook_reporting" component={RebookReporting} />
                <Route path="/rebook_history" component={RebookHistory} />
                <Route path="/help" component={NewCaseInPhreesiaPage} />
                {
                  (user_role==="admin") 
                    ? <><Route path="/appointment_type_change" component={MassAppointmentTypeChange} /></> 
                    : <></>
                }
              </React.Suspense>
            </Router>,
            (logged_out) ? <LoggedOutToast logged_out={true}/> : <PhreesiaSupport logged_out={logged_out}/>]
        }
      </MuiThemeProvider>
    )
  }
}

export default App;
