import React, { Component } from 'react';

class PhreesiaQuestionIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <g data-name="Layer 2">
          <g data-name="Layer 1">
            <circle cx={30} cy={30} r={30} fill="#f26b2e" />
            <path
              d="M22.25 22.25A7.75 7.75 0 1130 30v7.75"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
            />
            <path
              d="M31.75 44.21A1.75 1.75 0 0130 46a1.75 1.75 0 01-1.75-1.75A1.75 1.75 0 0130 42.46a1.75 1.75 0 011.75 1.75z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default PhreesiaQuestionIcon;