import React, { Component } from 'react';

class HelpScout extends Component {
	constructor(props) {
		super(props);
		this.state = {
				logged_out:props.logged_out
		};
	}
	componentWillReceiveProps(props) {
		if (props.logged_out !== this.state.logged_out) {
			 this.setState({logged_out:this.props.logged_out});
		}
	}
	componentDidMount() {
			window.Beacon('init', '2b75ba98-bb84-4a7d-a9d6-71372f5408cc');
	}
	render() {
		return (
			<div></div>
		)
	}
}
export default HelpScout;
