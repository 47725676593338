import React, { Component } from 'react';

import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';


class LoggedOutToast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged_out:props.logged_out
        };
        this.onClose=this.onClose.bind(this);
    }
    componentDidMount(){
        if(!window.location.pathname.includes("/offer/") && window.location.pathname!="/users/sign_in" && window.location.pathname!="/sign_in" && window.location.pathname!="/login" && window.location.pathname!="/v2/login" && window.location.pathname!="/v2/maintenance"){
            //window.location = window.location.protocol+'//'+window.location.host+'/v2/login';
            window.location = window.location.protocol+'//'+window.location.host+'/login';
        }else{
            this.setState({"logged_out":false}); //already on a sign in page
        }
    }
    componentWillReceiveProps(props) {
        if (props.logged_out !== this.state.logged_out) {
           this.setState({logged_out:this.props.logged_out});
        }
    }
    onClose() {
        this.setState({"logged_out":false});
    }

    render() {
        var {logged_out} = this.state;
        return (<></>
        /*
            <Snackbar
                style={{marginBottom:40}}
                anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
                open={logged_out}
                autoHideDuration={6000}
                onClose={this.onClose}
            >
                <SnackbarContent
                    style={{backgroundColor: amber[700],opacity: 0.9, marginRight: 20,}}
                    aria-describedby="client-snackbar"
                    message={
        <span id="client-snackbar" style={{
            display: 'flex',
            alignItems: 'center',
        }}>
          <InfoIcon style={{backgroundColor: amber[700],fontSize: 20, marginRight: 10}} />
          You may be logged out. Please refresh.
        </span>
      }
                    action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={this.onClose}>
          <CloseIcon style={{fontSize: 20}} />
        </IconButton>,
      ]}
                />
            </Snackbar>*/
        )
    }
}

export default LoggedOutToast;