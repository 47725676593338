import React, { Component } from 'react';
import { Fab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import PhreesiaQuestionIcon from './PhreesiaQuestionIcon';

const style = {
	icon: {
		margin: 0,
		top: 'auto',
		right: '20px',
		bottom: '20px',
		left: 'auto',
		position: 'relative',
	},
	iconWrapper: {
		height: '60px',
		position: 'fixed', 
		width: '60px',
		right: '20px',
		bottom: '20px',
	}
};

class PhreesiaSupport extends Component {
	constructor(props) {
		super(props);
		this.state = {
				logged_out:props.logged_out
		};
	}
	componentWillReceiveProps(props) {
		if (props.logged_out !== this.state.logged_out) {
			 this.setState({logged_out:this.props.logged_out});
		}
	}

  openSupportForm() {
    window.location.href = `${window.location.protocol}//${window.location.host}/v2/help`;
  }

	render() {
		return (
			<div style={style.iconWrapper} onClick={this.openSupportForm}>
				<Fab style={style.icon} aria-label="support">
					<PhreesiaQuestionIcon></PhreesiaQuestionIcon>
				</Fab>
			</div>
		)
	}
}
export default PhreesiaSupport;